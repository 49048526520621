import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const token = localStorage.getItem("token");

if (token) {
  Object.assign(headers, {
    Authorization: `Bearer ${token}`,
  });
}

const APIClientLand = axios.create({
  baseURL: `https://be.sitapic.com/landing/public`,
  headers: headers,
});
// const APIClientLand = axios.create({
//   baseURL: `http://192.168.68.59:7001`,
//   headers: headers,
// });

export default APIClientLand;
