import React, { useState, useEffect } from "react";
import moment from "moment";
import Full from "../load/Laoding";
import Swal from "sweetalert2";
import { getDataJurnal } from "../service/page/PageService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
const stopwatch = <FontAwesomeIcon icon={faDownload} />;

const JurnalPengaduan = () => {
  //loading
  const [loader, showLoader, hideLoader] = Full();

  const [dataJurnal, setDataJurnal] = useState([]);

  useEffect(() => {
    GetJurnal();
  }, []);

  const GetJurnal = () => {
    showLoader();
    getDataJurnal()
      .then((res) => {
        setDataJurnal(res.data.data);
        hideLoader();
      })
      .catch((err) => {
        Swal.fire({
          title: err.response.data.message,
          showConfirmButton: false,
          icon: "warning",
        });
        hideLoader();
      });
  };

  const [expanded, setExpanded] = useState({}); // Track expanded descriptions

  // Toggle the expanded state for a specific index
  const toggleExpanded = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* hero */}
      <div className="bg-custom-pattern2 bg-cover bg-center h-[36vh] w-full md:h-[50vh] lg:h-[100vh] flex items-end justify-start"></div>

      {/* penjelasan sitapic */}
      <section className="lg:pt-[3px] mb-8 mx-3">
        <div className="lg:my-12 lg:mx-28 lg:mb-16 mb-8">
          <h1 className=" text-primary text-md lg:text-[24px] font-semibold tracking-wide mt-6 mb-2 lg:mb-[24px] font-inter">
            CLINICAL EVIDENCE SITAPIC
          </h1>
          <div className="border-t-2 border-slate-950 lg:py-3 py-2"></div>
          <h5 className="text-[14px] lg:text-[16px]  pb-3 text-black font-reguler font-inter ">
            Silakan unduh clinical evidences penelitian berikut untuk menambah
            wawasan.
          </h5>
          <div className="lg:mt-6">
            <div className="relative overflow-x-auto overflow-y-auto max-h-80 shadow-md rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                <thead className="bg-[#1E6C93] text-xs text-white uppercase sticky top-0 z-10">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 sticky left-0 bg-[#1E6C93] z-3"
                    >
                      NO.
                    </th>
                    <th
                      scope="col"
                      width={"30%"}
                      className="px-6 py-3 sticky left-[50px] bg-[#1E6C93] z-3"
                    >
                      JUDUL CLINICAL EVIDENCE
                    </th>
                    <th scope="col" width={"30%"} className="px-6 py-3">
                      DESKRIPSI SINGKAT
                    </th>
                    <th scope="col" className="px-6 py-3">
                      TANGGAL PUBLISH
                    </th>
                    <th scope="col" className="px-6 py-3">
                      AKSI
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataJurnal.length === 0 ? (
                    <tr className="text-center font-inter ">
                      <td colSpan={"5"} className="py-6 text-lg">
                        Belum Ada Clinical Evidence
                      </td>
                    </tr>
                  ) : (
                    <>
                      {dataJurnal?.map((list, index) => {
                        const isExpanded = expanded[index];
                        const description = list.description;
                        const showReadMore = description.length > 100;
                        return (
                          <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 align-top"
                          >
                            <td className="px-6 py-3 font-medium text-gray-900 sticky left-0 bg-white z-3">
                              {index + 1}
                            </td>
                            <td className="px-6 py-3 font-medium text-gray-900 sticky left-[50px] bg-white z-3 ">
                              {list.jurnal_name}
                            </td>
                            <td className="px-6 py-3 font-medium text-gray-900 sticky left-[50px] bg-white z-3 align-top">
                              {showReadMore && !isExpanded ? (
                                <>
                                  {description.slice(0, 100)}...
                                  <button
                                    className="text-primary hover:underline"
                                    onClick={() => toggleExpanded(index)}
                                  >
                                    Selengkapnya
                                  </button>
                                </>
                              ) : (
                                <>
                                  {description}{" "}
                                  {showReadMore && (
                                    <button
                                      className="text-primary hover:underline"
                                      onClick={() => toggleExpanded(index)}
                                    >
                                      Sembunyikan
                                    </button>
                                  )}
                                </>
                              )}
                            </td>
                            <td className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap">
                              {moment(list.created_at).format("YYYY-MMMM-DD", {
                                locale: "id",
                              })}
                            </td>
                            <td className="px-6 py-3">
                              <a
                                href={`https://be.sitapic.com/landing/public/api/jurnal/file/${list.id}`}
                                download="jurnal.pdf"
                                target="_blank"
                                className="font-medium text-primary hover:underline"
                              >
                                Unduh <span>{stopwatch}</span>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {loader}
    </>
  );
};

export default JurnalPengaduan;
